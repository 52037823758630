body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.djangoql-completion {
    position: absolute;
    display: none;
    border: solid 1px #ccc;
    border-radius: 4px;
    background: white;
    background: var(--body-bg, white);
    min-width: 183px;
    font-size: 13px;
    z-index: 100000;
}

.djangoql-completion .active {
    background-color: #79aec8;
    color: white;
}

.djangoql-completion ul {
    padding: 2px 0;
    margin: 0;
    max-height: 295px;
    overflow: auto;
}

.djangoql-completion li {
    list-style: none;
    padding: 4px 10px;
    cursor: pointer;
}

.djangoql-completion li:hover {
    background-color: #c4e9fa;
    color: black;
}

.djangoql-completion li i {
    font-size: 0.9em;
    color: #ccc;
    float: right;
    font-style: normal;
}

.djangoql-completion .syntax-help {
    padding: 4px 10px 6px 10px;
    margin: 0;
    border-top: solid 1px #ccc;
    font-size: inherit;
}

/*
Pure CSS loading icon. Credit: https://loading.io/css/
*/
.djangoql-loading {
    display: block;
    position: relative;
}

.djangoql-loading:after {
    -moz-animation: djangoql-loading 1.2s linear infinite;
    -ms-animation: djangoql-loading 1.2s linear infinite;
    -webkit-animation: djangoql-loading 1.2s linear infinite;
    animation: djangoql-loading 1.2s linear infinite;
    border: 2px solid #aaa;
    border-color: #aaa transparent #aaa transparent;
    border-radius: 50%;
    content: " ";
    display: block;
    height: 12px;
    left: 50%;
    margin: -8px;
    position: absolute;
    top: 50%;
    width: 12px;
}

@keyframes djangoql-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.tblInsideTd {
    border-collapse: collapse!important;
    border: 3px solid white;
}
.tblInsideTd td {
    width: 100%;
    border: 1px solid #cccccc;
    padding: 2px 8px;
}
/*================Форматирование TopToolBar (пагинация, фильтра над таблицей)=========*/
.list-page .MuiTablePagination-toolbar {float: left!important;}
.list-page .MuiToolbar-gutters form {max-width: 1084px; margin-top: 0}
.list-page .MuiToolbar-gutters {justify-content: left!important}
/*==================Copy to clipboard=================================*/
.tooltipText {
    visibility: hidden;
    /*width: 140px;*/
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 9;
    margin-top: -36px;
    /*opacity: 0;*/
    transition: opacity 0.3s;
}

.compRichTextInput {width: 100%;}
.compRichTextInput .ql-editor{
    max-height: 600px!important;
    min-height: 100px;
    width: 100%;
}

/*.SimpleFormIterator {
        display: inline-flex;
}*/
.SimpleFormIterator section {display: contents}
.SimpleFormIterator section .ra-input {margin-right: 8px}

